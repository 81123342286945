import { Home, Palette, ThreeDRotation } from "@mui/icons-material";

export interface AppsListItem {
    title?: string,
    note?: string,
    href?: string,
    icon?: any,
    divider?: boolean,
};

export const AppsList: AppsListItem[] = [
    {
        title: "Maze Inner V3",
        note: "WebXR Maze Inner Cube Take 3",
        href: "https://maze-inner-v3.scriptaverse.com/",
        icon: <ThreeDRotation />,
    },
    {
        title: "Maze Inner V2",
        note: "WebXR Maze Inner Cube Take 2",
        href: "https://maze-inner-v2.scriptaverse.com/",
        icon: <ThreeDRotation />,
    },
    {
        title: "Maze Inner (bogs down on Oculus!)",
        note: "WebXR Maze Inner Cube",
        href: "https://maze-inner.scriptaverse.com/",
        icon: <ThreeDRotation />,
    },
    {
        title: "Maze Cube",
        note: "WebXR Maze Cube",
        href: "https://maze-cube.scriptaverse.com/",
        icon: <ThreeDRotation />,
    },
    {
        divider: true,
    },
    {
        title: "scriptaverse.com",
        note: "WebXR Home Page",
        href: "https://scriptaverse.com",
        icon: <Home />,
    },
    {
        title: "mitchallen.art",
        note: "The artist behind this site",
        href: "https://mitchallen.art",
        icon: <Palette />,
    },
];